import React, { useEffect, useState } from "react"
import { changeCategory, changeKeyword } from '../../reducks/items/operations'
import { MainContentH2 } from '../../components/UIkit'
import DocumentMeta from 'react-document-meta'
import { SiteTitle } from './common'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { ApiDir, ImagesDir, RouteDir, ItemImageDir } from '../../common'

const Home = () =>
{
  const dispatch = useDispatch()

  const [recommendItems, setRecommendItems] = useState([])

  const [newsId, setNewsId] = useState('')
  const [newsTitle, setNewsTitle] = useState('')
  const [newsBody, setNewsBody] = useState('')
  const [newsdate, setNewsdate] = useState('')

  const [Items, setItems] = useState([])

  useEffect(()=>
  {
    //おすすめ商品情報の取得
    let params = new URLSearchParams();
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectRecommendItemList.php',params)
    .then(function(response){
      setRecommendItems(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

    //お知らせ情報の取得
    params = new URLSearchParams();
    params.append('limit','1');
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPublicNewsList.php',params)
    .then(function(response){
      console.log(response.data[0])
      //お知らせIDのセット
      setNewsId(response.data[0].id)
      //お知らせタイトルのセット
      setNewsTitle(response.data[0].title)

      //お知らせ内容のHTML整形・セット
      const bodyHtml = new DOMParser().parseFromString(response.data[0].body, 'text/html')
      const maxLength = 100 //文字数上限
      let modStr = ''
      let bodyString = bodyHtml.documentElement.textContent
      let bodyText = bodyString.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
      if(bodyText.length > maxLength){
        modStr = bodyText.substr(0, maxLength) + '...'
      }
      else
      {
        modStr = bodyText
      }
      setNewsBody(modStr)

      //お知らせ日付の整形・セット
      const date = new Date(response.data[0].publication_datetime)
      setNewsdate(date.getFullYear()+ '.' + (date.getMonth() + 1) + '.' + date.getDate())
    })
    .catch(function(error){
      console.log(error)
      return
    })

    //商品一覧の取得
    params = new URLSearchParams();
    params.append('limit','8');
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPublicItemList.php',params)
    .then(function(response){
      setItems(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

  },[dispatch])

  const newsH2Style =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/top_news_h2_background_image.png')`
  }

  const menuListFruitStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_supplement.jpg')`
  }

  const menuListMeatStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_kansen.jpg')`
  }

  const menuListVegetableStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_vegetable.png')`
  }

  const menuListProcessedGoodsStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_Processed_goods.png')`
  }

  const menuListFlowerArrangementStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_flower_arrangement.png')`,
    backgroundSize: '35px',
    backgroundPosition: 'right 37px center'
  }

  const menuListOtherStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_other.png')`
  }

  const meta =
  {
    title: SiteTitle,
  }

  const selectItemListByCategory = (id)=>
  {
    dispatch(push(RouteDir + '/item/list?category=' + id))
  }

  return(
    <DocumentMeta {...meta}>
      <main className="top_main_content">
        <section className="shop_introduce_area">
          <div className="wrap">
            <h2 className="h2_title">AIM<span>とは？</span></h2>
            <div className="text_area">
              <img src={process.env.PUBLIC_URL + '/images/top_introduce_image.jpg'} alt="" />
              <p>新卒採用のやり方と聞いて、どんな方法をイメージしますか？</p>
              <p>「自社の魅力を理解してもらう」<br />「製品の強みを理解してもらう」</p>
              <p>これが一般的で、有効かつ実績豊富な方法だと思われるでしょう。</p>
              <p>しかし、この方法は有効かつ万能ではなくなっています。</p>
              <p><br />そもそもこれらは「自社に入るメリット」をアピールしています。</p>
              <p>このメリットだけでは差別化ができなくなってしまっているのです。 他社も似たようなことをどんどんアピールしており、すっかりコモディティ化しています。<br /></p>
              <p><br />そこで株式会社AIMではこれに代わる次世代の採用概念、「採用2.0」を開発しました。</p>
              <p><br />--目に見える「具体的な魅力」では差がつかない、それに代わるのは「抽象的魅力」である。--</p>

              <p><br />これが我々の考えです。  日ごろから学生に近い立場で声にならない声を聴き、彼らを観察し、「現場」にいるからこそたどり着けた理論はきっと納得して頂けるはずです。</p>

              <p>我々と一緒に次世代の「採用2.0」を進めていきませんか？</p>

            </div>
          </div>
        </section>
        <section className="recommend_item_list_area list_area">
          {/* <MainContentH2
            title = {'おすすめ商品recommendation'}
            boderColor = {'#00584D'}
          /> */}
          <h2 className="h2_title">おすすめ商品<span>recommendation</span></h2>
          <div className="item_list_area">
            {Array.isArray(recommendItems) && recommendItems.map((item, i) => (
              <div className={i<3?"recommend_item_card big_card":"recommend_item_card"} key={i} onClick={()=>dispatch(push(RouteDir+'/item/detail/'+item.id))}>
                <div className="thumbnail_area" style={item.path!==null?{backgroundImage:`url('${ItemImageDir}${item.path}')`}:{backgroundImage:`url('${process.env.PUBLIC_URL}/images/noimage.jpg')`}}></div>
                <p className="item_name">{item.name}</p>
                <p className="item_price">￥{(Math.floor(Number(item.price)*(1+(Number(item.tax)/100)))).toLocaleString()}(税込)</p>
              </div>
            ))}
          </div>
        </section>
        {/* <section className="news_area">
          <h2 style={newsH2Style}>株式会社AIM ECサイトからのお知らせ</h2>
          <div className="news_card" onClick={()=>dispatch(push(RouteDir + '/news/detail/'+newsId))}>
            <p className="title"><span className="date">{newsdate}</span>{newsTitle}</p>
            <p className="content_html" dangerouslySetInnerHTML={{__html: newsBody}}></p>
          </div>
          <button className="btn_type_1" onClick={()=>dispatch(push(RouteDir+'/news/list'))}>お知らせ一覧へ</button>
        </section> */}
        <section className="item_list_area list_area">
          {/* <MainContentH2
            title = {'商品一覧products list'}
            boderColor = {'#00584D'}
          /> */}
          <h2 className="h2_title">商品一覧<span>products list</span></h2>
          <div className="item_list">
            {Array.isArray(Items) && Items.map((item, i) => (
              <div className={"item_card"} key={i} onClick={()=>dispatch(push(RouteDir+'/item/detail/'+item.id))}>
                <div className="thumbnail_area" style={item.path!==null?{backgroundImage:`url('${ItemImageDir}${item.path}')`}:{backgroundImage:`url('${process.env.PUBLIC_URL}/images/noimage.jpg')`}}></div>
                <p className="item_name">{item.name}</p>
                <p className="item_price">￥{(Math.floor(Number(item.price)*(1+(Number(item.tax)/100)))).toLocaleString()}(税込)</p>
              </div>
            ))}
          </div>
          <button className="btn_type_1" onClick={()=>dispatch(push(RouteDir+'/item/list'))}>商品一覧へ</button>
        </section>
        <section className="category_list_area">
          {/* <MainContentH2
            title = {'商品カテゴリ'}
            boderColor = {'#00584D'}
          /> */}
          <h2 className="h2_title">商品カテゴリ<span>category</span></h2>
          <div className="category_list">
            <div className="category_card">
              <img onClick={()=>selectItemListByCategory(13)} src={process.env.PUBLIC_URL + '/images/category_btn_01.jpg'} alt="" />
            </div>
            <div className="category_card">
              <img onClick={()=>selectItemListByCategory(14)} src={process.env.PUBLIC_URL + '/images/category_btn_02.jpg'} alt="" />
            </div>
          </div>
        </section>
      </main>
    </DocumentMeta>
  )
}

export default Home